









































import Vue from 'vue'

export type DataType = {
  modal: boolean;
}

export default Vue.extend({
  name: 'SelectField',
  props: {
    value: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Array,
      required: false,
      default: null
    }
  },
  data (): DataType {
    return {
      modal: false
    }
  },
  computed: {
    selected: {
      get (): string | null {
        return this.value
      },
      set (newVal: string): void {
        if (this.value !== newVal) this.$emit('input', newVal)
      }
    }
  },
  methods: {
    selectValue (val: string): void {
      if (this.value !== val) this.$emit('input', val)
      this.modal = false
    }
  }
})
